$(document).ready(function(){

  var formLogin = $('#formLogin');

  formLogin.on('submit', function(e) {
    e.preventDefault();
    putForm(formLogin);
  });

});
